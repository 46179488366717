<template>
  <keep-alive include="JeomsinInfo">
    <router-view />
  </keep-alive>
</template>

<script>
import "@/assets/js/promotion/jeomsin/common";

export default {
  name: "JeomsinLayout",
};
</script>

<style scoped>
@import "~@/assets/fonts/gmarketSans.css";
@import "~@/assets/css/promotion/jeomsin/normalize.css";
@import "~@/assets/css/promotion/jeomsin/common.css";
@import "~@/assets/css/promotion/jeomsin/content.css";
</style>
